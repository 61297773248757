import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { useStateContext } from '../context/State';

const SurveyLink = ({ surveyId, inviteId, response }) => (
    <Button disabled={Boolean(response)} component={Link} to={`/survey/${surveyId}/${inviteId}`}>
        {Boolean(response) ? 'Feedback provided' : 'Provide feedback'}
    </Button>
)

const RequestCard = ({ surveyId, inviteId, requesterEmail, response }) => (
    <Card sx={{ mb: 2 }}>
        <CardContent sx={{ pb: 0 }}>
            <Typography component="div">{requesterEmail}</Typography>
        </CardContent>
        <CardActions sx={{ pt: 0, pb: 1 }}>
            <SurveyLink
                surveyId={surveyId}
                inviteId={inviteId}
                response={response}
            />
        </CardActions>
    </Card>
)

export const RequestsPage = () => {
    const { state: { invites } } = useStateContext();

    if (!invites?.length) {
        return <Typography align="center" variant="h5">You don't have any survey requests yet.</Typography>
    }

    const sortedInvites = [...invites].sort((i1, i2) => {
        if (i1.response && i2.response) {
            return 0;
        } else if (i1.response) {
            return 1;
        } else if (i2.response) {
            return -1;
        } else {
            return 0;
        }
    });

    return (
        <>
            <Typography align="center" variant="h5" sx={{ mb: 5 }}>These people requested your feedback:</Typography>
            {sortedInvites.map(({ surveyId, inviteId, response, requesterEmail }) => {
                return <RequestCard
                    key={surveyId}
                    surveyId={surveyId}
                    inviteId={inviteId}
                    requesterEmail={requesterEmail}
                    response={response}
                />
            })}
        </>
    )
};